<template>
  <v-btn
    class="ml-2"
    min-width="0"
    text
    exact
    @click="logout"
  >
    <v-icon color="white">
      mdi-logout
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'DefaultLogout',
    methods: {
      logout () {
        this.$store.dispatch('user/logout')
      },
    },
  }
</script>
